import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link, Script } from 'gatsby'
import { SiGnuprivacyguard } from '@react-icons/all-files/si/SiGnuprivacyguard'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Script
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'AUVA Formación en Inteligencia Artificial' },
              { name: 'keywords', content: 'AUVA, IA, Inteligencia Artificial, DeepLearning, MachineLearning, Aprendizaje Automático, modelos generativos, on-line, presencial, in-company, formación, chatgpt, python, sklearn, pandas, torch, pytorch, tensorflow, matplotlib, numpy' },
            ]}
          >
            <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />
            <html lang="en" />
          </Script>
          {content}
            {/* AUVA Formación IA no utiliza cookies.{' '} */}
            <span
              style={{
                fontSize: '14px',
                textAlign: 'center',
                marginLeft: '20px',
              }}
            >
              <span className="icon -lock">
                <SiGnuprivacyguard />
              </span>{' '}
              <Link to="/privacy" alt="Política de privacidad">
                Política de privacidad
              </Link>
            </span>

        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
